<template>
  <div class="component-root">
    <!-- 业务推荐 -->
    <div class="message-header">
      <em class="icon-dot"></em>相关业务推荐
    </div>
    <ul class="business">
      <a :href="item.url" target="_blank" v-for="(item, index) in message.content" :key="index">
        <li
          class="iconClass"
          :style="{ backgroundImage: 'url('+ item.icon +')' }"
          @click="selectItem(item)"
        ></li>
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RelBusiness',
  props: {
    message: Object
  },
  computed: {},
  methods: {
    selectItem (item) {
      if (item.url) {
        // 如果存在连接直接跳转离开
        return
      }
      console.log('relBusiness-selected-item')
      // 提问
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: item.name,
        dataType: 'relBusiness-selected',
        onlyRobot: true
      })
    }
  }
}
</script>
